.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url(../images/faq//plus.png) !important
     ;
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
    background-image: url(../images//faq/minus.png) !important;
    transform: rotate(-180deg);
}


.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #000;
    text-align: left;
    font-weight: 400 !important;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.accordion-button:not(.collapsed) {
   
    background-color: #e7f1ff;
    color: #000 !important;
    font-weight: 500 !important;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}

.accordion-body p{
    font-size: 14px;
    font-weight: 400;
    color: #000;
    opacity: 0.6;
}


.faq-heading{
 width: fit-content;
}

.faq-heading::after {
    content: '';
    display: block;
    width: 150px;
    height: 3px;
    margin-top: 16px;
    background: #F16126;
    transition: width .3s;
}