:root{
    --orange:#f16126;
    --white:#fff;
    --lightGreen:#26c976;
}
.login{
    background-color: var(--lightGreen) !important;
    color: var(--white) !important ;
    margin-right: 7px;
    padding: 15px 20px !important;
}
.login:hover{
    cursor: pointer !important;
}
.header-section{
   border-bottom: 0.5px solid rgb(121, 118, 118) ;
}

@media screen and (max-width: 1200px) {

    .login{
        padding: 10px 15px !important ;
    }
}