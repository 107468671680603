.Grade-section {

    .grade-wrapper{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .grade-header{


        
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            justify-content: center;
            max-width: 600px;
            margin: 0px auto;
    
            p{
                text-align: center;
            }
        }
    }
  
}

.grade-book-wrapper{
    position: relative;
    max-width: 691px;
    height: 809px;

    .grade-side-board{
        position: absolute;
        top: 106px;
        right: -161px;
        padding: 32px 16px;
     width: 312px;
     height: 500px;
     overflow-y: auto;
     background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #FFF;
     box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.10), 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
    }
}
.count-div{
    width: 24px;
    height: 24px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 1px solid #cdcdcd;
    background-color: #fff;

}



@media only screen and (max-width: 1040px) and (min-width: 576px)  {

    .grade-side-board {
        right: 10px !important;
        top: 170px !important;
       }
}


@media only screen and (max-width: 576px) and (min-width: 0px)  {
    .grade-side-board {
        right: 16px !important;
        bottom: 0px !important;
        top: unset !important;
       }
}


.stepper-col{
    padding: 0px 12px;
}


.item-title{
  
    font-size: 14px;

}



.item-title::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    margin-top: 16px;
    background: #F16126;
    transition: width .3s;
}

.stepper-col:hover .item-title::after{
    width: 100%;
}




