.pricing-plan-section {
    background-image: url(../images/pricing/goal.png);
    background-repeat: no-repeat;
    background-position: center;
}

.form-switch .form-check-input {
    width: 3em !important;
    margin-left: -2.5em;
    background-image: url(../images/pricing/orangeswitch.png) !important;
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out;
    outline: none !important;
    box-shadow: none !important;
}

.form-check-input:checked {
    background-color: transparent !important;
    border-color: #FA3316 !important;
    box-shadow: none !important;


}

.form-check-input:focus {
    outline: none;
}

.custom-badge {
    background: #f974162c;
    border-radius: 99px !important
}

.badge-title {
    font-size: 12px;
    font-weight: 500;
    color: #F97316;
}

.plan-card-wrapper {
    padding: 32px 24px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.10);

    background: #FFF;
    position: relative;
}

.custom-button {
    border-radius: 6px;
    padding: 12px 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.10);
    color: #FFF;
    background: conic-gradient(from 222deg at 100% 90%, #FA3316 37.501200735569deg, #F97316 300.9749436378479deg);
}

.flex-1 {
    flex: 1;
}

.featuered-card {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 0px 12px 0px 12px;
    background: conic-gradient(from 222deg at 100% 90%, #FA3316 37.501200735569deg, #F97316 300.9749436378479deg);
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
}


.bg-f33 {
    background-color: #1F1F33 !important;
    transform: scaleY(1.1);

}

.cl-white {
    color: #FFF !important;
}

.r-gap-60px {
    row-gap: 60px;
}

.link-tag {
    color: rgba(0, 133, 255, 0.80) !important;
    font-family: Barlow;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
    text-transform: capitalize;
}