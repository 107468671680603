.avatar-badg{
 
    background-color: #F16126 !important;
    color: #fff;
    font-weight: 500;
}

.count-badge{
    position: absolute;
    bottom: -10px;
    left: 12px;
}